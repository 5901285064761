import React from "react";
import styled from "styled-components";
import Resources from '../../resources/afiservicios-resources.json';
import { graphql, StaticQuery } from "gatsby"
import Img from 'gatsby-image'
import CustomList from "./list"
import { useSiteContent } from "../hooks/use-site-content"

const HistoryAssetsWrapper = styled.div`
  margin-top: 80px;
`;

const HistoryAssetsTitle = styled.label`
  font-family: var(${Resources.history.assets.title.font});
  font-size: ${Resources.history.assets.title.size};
  color: var(${Resources.history.assets.title.color});
  text-transform: uppercase;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.history.assets.title.size_small};
  }
`;

const HistoryAssetsContent = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 0.2fr;
  grid-gap: 2em;
  height: 300px;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    height: auto;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr;
    height: auto;
  }
`;

const HistoryAssetsTextContent = styled.div`
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-row: 1;
    margin-top: 30px;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-row: 1;
    margin-top: 30px;
  }
`;

const HistoryAssetsImgContent = styled.div`
  max-height: 300px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .img {
    height: 100%;
    width: 100%;
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-row: 2;
    
    .img {
      width: 80%;
      margin: auto;
      margin-top: -30px;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-row: 2;
    
    .img {
      width: 30%;
      margin: auto;
      margin-top: -30px;
    }
  }
`;


const HistoryAssetsSection = () => {
  const {history: {activos}} = useSiteContent()
  return (
    <StaticQuery
      query={graphql`
      query {
        img: file(name: { eq: "history_2_img" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
      render={data => (
        <HistoryAssetsWrapper>
          <div className= {"container"}>
            <HistoryAssetsTitle>
              {Resources.history.assets.title.label}
            </HistoryAssetsTitle>
            <HistoryAssetsContent>
              <HistoryAssetsTextContent>
                <CustomList
                  list={activos}
                />
              </HistoryAssetsTextContent>
              <HistoryAssetsImgContent>
                <Img fluid={data.img.childImageSharp.fluid}
                     className="img"
                     imgStyle={{ objectFit: "contain", width: '100%', height: '100%' }} />
              </HistoryAssetsImgContent>
            </HistoryAssetsContent>
          </div>
        </HistoryAssetsWrapper>
      )}
    />
  );
}

export default HistoryAssetsSection;
