import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HistoryPrincipalSection from "../components/history-principal-section"
import HistoryClientsSection from "../components/history-clients-section"
import HistoryAssetsSection from "../components/history-assets-section"
import HistoryTransportSection from "../components/history-transport-section"
import HistoryTendersSection from "../components/history-tenders-section"
import HistoryFSSection from "../components/history-finance-structure"
import HistoryConSection from "../components/history-concessions"
import Fade from 'react-reveal/Fade'
import "../styles/global.css"
import "../styles/background-image.css"


const HistoryPage = () => (
  <Layout fixedNavbar={true} location="casos-de-exito">
    <SEO title="Casos de éxito" />
      <Fade big>
        <HistoryPrincipalSection />
      </Fade>
      <Fade big>
      <HistoryClientsSection />
      </Fade>
      <Fade right>
      <HistoryAssetsSection />
      </Fade>
      <Fade big>
      <HistoryTransportSection />
      </Fade>
      <Fade left>
      <HistoryTendersSection />
      </Fade>
      <Fade right>
      <HistoryFSSection />
      </Fade>
      <Fade big>
      <HistoryConSection />
      </Fade>
  </Layout>
)

export default HistoryPage
