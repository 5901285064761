import React from "react";
import styled from "styled-components";
import Resources from '../../resources/afiservicios-resources.json';
import { graphql, StaticQuery } from "gatsby"
import Img from 'gatsby-image'
import CustomList from "./list"
import { useSiteContent } from "../hooks/use-site-content"

const HistoryClientsWrapper = styled.div`
  background-color: var(--secondary-color);
  height: auto;
  padding: 60px 30px;
  position:relative;
  margin-top: 100px;
`;

const HistoryClientsTitle = styled.label`
  font-family: var(${Resources.history.clients.title.font});
  font-size: ${Resources.history.clients.title.size};
  color: var(${Resources.history.clients.title.color});
  text-transform: uppercase;
`;

const HistoryClientsContent = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 0.8fr;
  grid-gap: 2em;
  height: 300px;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    height: auto;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr;
    height: auto;
  }
`;

const HistoryClientsTextContent = styled.div`
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-row: 2;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-row: 2;
  }
`;

const HistoryClientsImgContent = styled.div`
  max-height: 300px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .img {
    height: 50%;
    width: 100%;
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-row: 1;
    
    .img {
      width: 50%;
      margin: auto;
      margin-top: 45px;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-row: 1;
    
    .img {
      width: 20%;
      margin: auto;
      margin-top: 30px;
    }
  }
`;

const HistoryClientsSection = () => {
  const {history: {clientes}} = useSiteContent();
  
  return (
    <StaticQuery
      query={graphql`
      query {
        img: file(name: { eq: "history_1_img" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
      render={data => (
        <HistoryClientsWrapper>
          <div className={"container"}>
            <HistoryClientsTitle>
              {Resources.history.clients.title.label}
            </HistoryClientsTitle>
            <HistoryClientsContent>
              <HistoryClientsImgContent>
                <Img fluid={data.img.childImageSharp.fluid}
                     className="img"
                     imgStyle={{ objectFit: "contain", width: '100%', height: '100%' }} />
              </HistoryClientsImgContent>
              <HistoryClientsTextContent>
                <CustomList
                  list={clientes}
                />
              </HistoryClientsTextContent>
            </HistoryClientsContent>
          </div>
        </HistoryClientsWrapper>
      )}
    />
  );
}

export default HistoryClientsSection;
