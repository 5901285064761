import React from "react";
import styled from "styled-components";
import Resources from '../../resources/afiservicios-resources.json';
import { graphql, StaticQuery } from "gatsby"
import Img from 'gatsby-image'
import CustomList from "./list"
import { useSiteContent } from "../hooks/use-site-content"

const HistoryTransportWrapper = styled.div`
  background-color: var(--secondary-color);
  height: auto;
  padding: 60px 30px;
  margin-left: -13%;
  padding-left: 13%;
  margin-top: 80px;
`;

const HistoryTransportTitle = styled.label`
  font-family: var(${Resources.history.transport.title.font});
  font-size: ${Resources.history.transport.title.size};
  color: var(${Resources.history.transport.title.color});
  text-transform: uppercase;
`;

const HistoryTransportContent = styled.div`
  display: grid;
  grid-template-columns: 0.3fr 0.7fr;
  grid-gap: 2em;
  height: 300px;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    height: auto;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr;
    height: auto;
  }
`;

const HistoryTransportContent2 = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 0.3fr;
  grid-gap: 2em;
  height: 300px;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    height: auto;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr;
    height: auto;
  }
`;

const HistoryTransportTextContent = styled.div`
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-row: 2;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-row: 2;
  }
`;

const HistoryTransportImgContent = styled.div`
  max-height: 300px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .img {
    height: 50%;
    width: 100%;
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-row: 1;
    
    .img {
      width: 80%;
      margin: auto;
      margin-top: 45px;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-row: 1;
    
    .img {
      width: 20%;
      margin: auto;
      margin-top: 30px;
    }
  }
`;

const HistoryTransportSection = () => {
  const {history: {transport, transport2}} = useSiteContent()
  return (
    <StaticQuery
      query={graphql`
      query {
        cisa: file(name: { eq: "history_4_img" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        },
        mb: file(name: { eq: "history_5_img" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
      render={data => (
        <HistoryTransportWrapper>
          <div className={"container"}>
            <HistoryTransportTitle>
              {Resources.history.transport.title.label}
            </HistoryTransportTitle>
            <HistoryTransportContent>
              <HistoryTransportImgContent>
                <Img fluid={data.cisa.childImageSharp.fluid}
                     className="img"
                     imgStyle={{ objectFit: "contain", width: '100%', height: '100%' }} />
              </HistoryTransportImgContent>
              <HistoryTransportTextContent>
                <CustomList
                  list={transport}
                />
              </HistoryTransportTextContent>
            </HistoryTransportContent>
            <HistoryTransportContent2>
              <HistoryTransportTextContent>
                <CustomList
                  list={transport2}
                />
              </HistoryTransportTextContent>
              <HistoryTransportImgContent>
                <Img fluid={data.mb.childImageSharp.fluid}
                     className="img"
                     imgStyle={{ objectFit: "contain", width: '100%', height: '100%' }} />
              </HistoryTransportImgContent>
            </HistoryTransportContent2>
          </div>
        </HistoryTransportWrapper>
      )}
    />
  );
}

export default HistoryTransportSection;
