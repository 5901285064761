import React from "react";
import styled from "styled-components";
import Resources from '../../resources/afiservicios-resources.json';
import { graphql, StaticQuery } from "gatsby"
import Img from 'gatsby-image'
import CustomList from "./list"
import { useSiteContent } from "../hooks/use-site-content"

const HistoryTendersWrapper = styled.div`
  background-color: var(--four-color);
  height: auto;
  padding: 20px 30px;
  margin-right: -12%;
  padding-right: 12%;
  margin-top: 80px;
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    margin-right: -4.25%;
    padding-right: 4.25%;
  }
`;

const HistoryTendersTitle = styled.label`
  font-family: var(${Resources.history.tenders.title.font});
  font-size: ${Resources.history.tenders.title.size};
  color: var(${Resources.history.tenders.title.color});
  text-transform: uppercase;
`;

const HistoryTendersContent2 = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 0.3fr;
  grid-gap: 2em;
  height: 300px;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    height: auto;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr;
    height: auto;
  }
`;

const HistoryTendersTextContent = styled.div`
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-row: 2;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-row: 2;
  }
`;

const HistoryTendersImgContent = styled.div`
  max-height: 300px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .img {
    height: 100%;
    width: 100%;
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-row: 1;
    
    .img {
      width: 100%;
      margin: auto;
      margin-top: 45px;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-row: 1;
    
    .img {
      width: 40%;
      margin: auto;
      margin-top: 30px;
    }
  }
`;

const HistoryTendersSection = () => {
  const {history: {tenders}} = useSiteContent()
  return (
    <StaticQuery
      query={graphql`
      query {
        img: file(name: { eq: "history_2_img" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
      render={data => (
        <HistoryTendersWrapper>
          <div className={"container"}>
            <HistoryTendersTitle>
              {Resources.history.tenders.title.label}
            </HistoryTendersTitle>
            <HistoryTendersContent2>
              <HistoryTendersTextContent>
                <CustomList
                  list={ tenders }
                  color="--six-color"
                />
              </HistoryTendersTextContent>
              <HistoryTendersImgContent>
                <Img fluid={data.img.childImageSharp.fluid}
                     className="img"
                     imgStyle={{ objectFit: "contain", width: '100%', height: '100%' }} />
              </HistoryTendersImgContent>
            </HistoryTendersContent2>
          </div>
        </HistoryTendersWrapper>
      )}
    />
  );
}

export default HistoryTendersSection;
