import React from "react";
import styled from "styled-components";
import Resources from '../../resources/afiservicios-resources.json';
import { useSiteContent } from "../hooks/use-site-content"

const HistoryConWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  height: 300px;
  padding: 60px 30px;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    height: auto;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr;
    height: auto;
  }
`;

const HistoryConTextContent = styled.div`
  text-align: left;
`;

const HistoryConTitle = styled.label`
  font-family: var(${Resources.history.concessions.title.font});
  font-size: ${Resources.history.concessions.title.size};
  color: var(${Resources.history.concessions.title.color});
  text-transform: uppercase;
`;

const HistoryConDescription = styled.p`
  font-family: var(${Resources.history.concessions.description.font});
  font-size: ${Resources.history.concessions.description.size};
  color: var(${Resources.history.concessions.description.color});
  line-height: ${Resources.history.concessions.description.line_height};
`;

const HistoryConSection = () => {
  const {history: {concessions}} = useSiteContent()
  return (
    <HistoryConWrapper>
      <div className={"container"}>
        <HistoryConTextContent>
          <HistoryConTitle>
            {Resources.history.concessions.title.label}
          </HistoryConTitle>
          <br/>
          <br/>
          <HistoryConDescription>
            {concessions}
          </HistoryConDescription>
        </HistoryConTextContent>
      </div>
    </HistoryConWrapper>
  );
}

export default HistoryConSection;
