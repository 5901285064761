import React from "react";
import styled from "styled-components";
import Resources from '../../resources/afiservicios-resources.json';
import { graphql, StaticQuery } from "gatsby"
import Img from 'gatsby-image'
import { useSiteContent } from "../hooks/use-site-content"

const HistoryPrincipalWrapper = styled.div`
  
  height: 300px;
  margin-top: 160px;

  .grid-data {
    display: grid;
    grid-template-columns: 4fr 6fr;
    grid-gap: 2em;
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    height: auto;
    margin-top: 0;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr;
    height: auto;
    margin-top: 0;
  }
`;

const HistoryPrincipalTextContent = styled.div`
  text-align: left;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-row: 2;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-row: 2;
  }
`;

const HistoryPrincipalImgContent = styled.div`
  max-height: 300px;

  .img {
    max-height: 300px;
    max-width: 100%;
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-row: 1;
    height: auto;
    
    .img {
      border-radius: 0;
      width: 100vw;
      max-width: 100vw;
      margin-left: -30px;
      margin-right: -30px;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-row: 1;
    height: auto;
    
    .img {
      border-radius: 0;
      width: 100vw;
      max-width: 100vw;
      margin-left: -30px;
      margin-right: -30px;
    }
  }
`;

const HistoryPrincipalTitle = styled.label`
  font-family: var(${Resources.history.principal.title.font});
  font-size: ${Resources.history.principal.title.size};
  color: var(${Resources.history.principal.title.color});
  text-transform: uppercase;
`;

const HistoryPrincipalDescription = styled.p`
  font-family: var(${Resources.history.principal.description.font});
  font-size: ${Resources.history.principal.description.size};
  color: var(${Resources.history.principal.description.color});
  line-height: ${Resources.history.principal.description.line_height};
`;

const HistoryPrincipalSection = () => {
  const {history: {description}} = useSiteContent();
  return (
    <StaticQuery
      query={graphql`
      query {
        img: file(name: { eq: "history_img" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
      render={data => (
        <HistoryPrincipalWrapper>
          <div className={"container grid-data"}>
            <HistoryPrincipalTextContent>
              <HistoryPrincipalTitle>
                {Resources.history.principal.title.label}
              </HistoryPrincipalTitle>
              <HistoryPrincipalDescription>
                {description}
              </HistoryPrincipalDescription>
            </HistoryPrincipalTextContent>
            <HistoryPrincipalImgContent>
              <Img fluid={data.img.childImageSharp.fluid}
                   className="img"
                   imgStyle={{ objectFit: "cover", width: '100%', height: '100%' }} />
            </HistoryPrincipalImgContent>
          </div>
        </HistoryPrincipalWrapper>
      )}
    />
  );
}

export default HistoryPrincipalSection;
