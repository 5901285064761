import React from "react";
import styled from "styled-components";
import Resources from '../../resources/afiservicios-resources.json';
import { graphql, StaticQuery } from "gatsby"
import Img from 'gatsby-image'
import CustomList from "./list"
import { useSiteContent } from "../hooks/use-site-content"

const HistoryFSWrapper = styled.div`
  background-color: var(--secondary-color);
  margin-top: 80px;
  padding: 60px 30px;
  height: auto;
`;

const HistoryFSTitle = styled.label`
  font-family: var(${Resources.history.fs.title.font});
  font-size: ${Resources.history.fs.title.size};
  color: var(${Resources.history.fs.title.color});
  text-transform: uppercase;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    font-size: ${Resources.history.assets.title.size_small};
  }
`;

const HistoryFSContent = styled.div`
  display: grid;
  grid-template-columns: 0.3fr 0.7fr;
  grid-gap: 2em;
  height: auto;

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    height: auto;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: 1fr;
    height: auto;
  }
`;

const HistoryFSTextContent = styled.div`
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-row: 2;
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-row: 2;
  }
`;

const HistoryFSImgContent = styled.div`
  max-height: 300px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .img {
    height: 50%;
    width: 60%;
    margin: auto;
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 767px) {
    grid-row: 1;
    
    .img {
      width: 80%;
      margin: auto;
      margin-top: 45px;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    grid-row: 1;
    
    .img {
      width: 20%;
      margin: auto;
      margin-top: 30px;
    }
  }
`;

const HistoryFSSection = () => {
  const {history: {fs, fs2}} = useSiteContent()
  return (
    <StaticQuery
      query={graphql`
      query {
        aur: file(name: { eq: "history_5_img" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        },
        dem: file(name: { eq: "history_6_img" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
      render={data => (
        <HistoryFSWrapper>
          <div className={"container"}>
            <HistoryFSTitle>
              {Resources.history.fs.title.label}
            </HistoryFSTitle>
            <br/>
            <br/>
            <br/>
            <HistoryFSContent>
              <HistoryFSImgContent>
                <Img fluid={data.aur.childImageSharp.fluid}
                     className="img"
                     imgStyle={{ objectFit: "contain", width: '100%', height: '100%' }} />
              </HistoryFSImgContent>
              <HistoryFSTextContent>
                <CustomList
                  list={fs}
                />
              </HistoryFSTextContent>
            </HistoryFSContent>
            <HistoryFSContent>
              <HistoryFSImgContent>
                <Img fluid={data.dem.childImageSharp.fluid}
                     className="img"
                     imgStyle={{ objectFit: "contain", width: '100%', height: '100%' }} />
              </HistoryFSImgContent>
              <HistoryFSTextContent>
                <CustomList
                  list={fs2}
                />
              </HistoryFSTextContent>
            </HistoryFSContent>
          </div>
        </HistoryFSWrapper>
      )}
    />
  );
}

export default HistoryFSSection;
